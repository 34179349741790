const RECOMMENDATION_TYPES = [{id: 'NEW_PRODUCT', label: 'New Product'}, {id: 'SIMILIAR_PRODUCT', label: 'Similar Product'}];

const TicketMetaData = {

    DIGITICKET_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Code"}
    ],

    TIGERTICKET_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
    ],
    TIGERTICKET_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Created Date', id: 'createdDate', type: 'datetime', editable: 'never'}, {label: "Created By", id: 'createdBy', type: 'text', editable: 'never'}],
        [{label: 'Last Modified Date', id:'lastModifiedDate', type: 'datetime', editable: 'never', isExpertField: true}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never', isExpertField: true}]
    ],
    TIGERTICKET_DETAILS_TICKET: [
        [{label: 'Code', id: 'code', type: 'text', editable: 'never'}, {label: 'PIN', id: 'pin', type: 'text', editable: 'never'}, {label: "NFC-Card ID", id: 'nfcCardId', type: 'text', editable: 'never'}, {label: "NFC-Card Code", id: 'nfcCardCode', type: 'text', editable: 'never'}],
        [{label: 'Note', id:'note', type: 'textarea', editable: 'true', rows: 5}]
    ],
    TIGERTICKET_DETAILS_STATUS: [
        [{label: 'Used', id: 'used', type: 'boolean', editable: 'never'}, {label: 'Redeemed By', id: 'redeemedByAccountId', type: 'text', editable: 'never'}, {label: "Redeemed At", id: 'redeemedAt', type: 'datetime', editable: 'never'}],
        [{label: 'Transaction ID', id:'transactionId', type: 'text', editable: 'never'}, {label: 'Mapped Billwerk Product ID', id: 'mappedBillwerkProductId', editable: 'never', type: 'text'}]
    ],

    DIGITICKET_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Created Date', id: 'createdDate', type: 'datetime', editable: 'never'}, {label: "Created By", id: 'createdBy', type: 'text', editable: 'never'}],
        [{label: 'Last Modified Date', id:'lastModifiedDate', type: 'datetime', editable: 'never', isExpertField: true}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never', isExpertField: true}]
    ],
    DIGITICKET_DETAILS_TICKET: [
        [{label: 'Code', id: 'code', type: 'text', editable: 'never'}, {label: 'PIN', id: 'pin', type: 'text', editable: 'never'}, {label: 'Source', id: 'source', type: 'select', options: ["CUSTOMER", "SUPPORT", "GENERATOR"], editable: 'true'}],
        [{label: "Created By Email", id: 'createdByEmail', type: 'text', editable: 'never'}, {label: "Created By Account ID", id: 'createdByAccountId', type: 'text', editable: 'never'}, {label: 'Bought At', id: 'boughtAt', type: 'datetime', editable: 'never'}],
        [{label: 'Note', id:'note', type: 'textarea', editable: 'true', rows: 5}]
    ],
    DIGITICKET_DETAILS_STATUS: [
        [{label: 'Used', id: 'used', type: 'boolean', editable: 'true'}, {label: 'Redeemed By', id: 'redeemedByAccountId', type: 'text', editable: 'never'}, {label: "Redeemed At", id: 'redeemedAt', type: 'datetime', editable: 'never'}],
        [{label: 'Transaction ID', id:'transactionId', type: 'text', editable: 'never'}, {label: 'Mapped Billwerk Product ID', id: 'mappedBillwerkProductId', editable: 'never', type: 'text'}]
    ],

    COUPON_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Created Date', id: 'createdDate', type: 'datetime', editable: 'never'}, {label: "Created By", id: 'createdBy', type: 'text', editable: 'never'}],
        [{label: 'Last Modified Date', id:'lastModifiedDate', type: 'datetime', editable: 'never', isExpertField: true}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never', isExpertField: true}]
    ],
    COUPON_DETAILS_TICKET: [
        [{label: 'Code', id: 'code', type: 'text', editable: 'never'}, {label: 'PIN', id: 'pin', type: 'text', editable: 'never'}, {label: 'NFC Card ID', id: 'nfcCardId', type: 'text', editable: 'never'}, {label: 'NFC Card Code', id: 'nfcCardCode', type: 'text', editable: 'never'}],
        [{label: 'Note', id:'note', type: 'textarea', editable: 'true', rows: 5}]
    ],
    COUPON_DETAILS_STATUS: [
        [{label: 'Used', id: 'used', type: 'boolean', editable: 'never'}, {label: 'Redeemed By', id: 'redeemedByAccountId', type: 'text', editable: 'never'}, {label: "Redeemed At", id: 'redeemedAt', type: 'datetime', editable: 'never'}],
        [{label: 'Transaction ID', id:'transactionId', type: 'text', editable: 'never'}, {label: 'Mapped Billwerk Product ID', id: 'mappedBillwerkProductId', editable: 'never', type: 'text'}]
    ],

    RECOMMENDATION_PRODUCTS_SORT_PARAMS: [],
    RECOMMENDATION_PRODUCT_TYPES: RECOMMENDATION_TYPES,
};

export default TicketMetaData;