import {
    CreateBillResource, GetFilteredBillResources, GetBillResource, UpdateBillResource
} from "../../clients/PurchaseClient";

//API METHODS - DIGITICKETS
export async function GetDigiticketPage(page){
    return await GetFilteredBillResources('api/purchases/digitickets', {page: page});
}

export async function GetDigiticketByProductIds(page, productIds) {
    console.log(productIds);
    return await GetFilteredBillResources('api/purchases/digitickets/search/by-product-id', {page: page, productId: productIds});
}

export async function GetDigiticketByCode(code, page) {
    return await GetFilteredBillResources('api/purchases/digitickets/search/by-code', {code: code, page: page});
}

export async function CreateDigiticketOrder(code, pin, accountId) {
    return await CreateBillResource('api/purchases/order/submit-ticket-order-by-support?accountId=' + accountId, {code: code, pin: pin});
}

export async function GetDigiticketByAccount(accountId, page) {
    return await GetFilteredBillResources('api/purchases/digitickets/search/by-account-id', {accountId: accountId, page: page});
}

export async function CreateDigiticket(digiticket) {
    return await CreateBillResource('api/purchases/digitickets', digiticket);
}

export async function UpdateDigiticket(digiticket) {
    return await UpdateBillResource('api/purchases/digitickets/' + digiticket.id, digiticket);
}

export async function GetDigiticket(id) {
    return await GetBillResource('api/purchases/digitickets/' + id);
}

export async function ResetDigiticket(id) {
    return await GetBillResource('api/purchases/digitickets/' + id + '/reset');
}

export async function CancelDigiticket(id) {
    return await GetBillResource('api/purchases/digitickets/' + id + '/cancel');
}

//API METHODS - TIGERTICKETS
export async function GetTigerticketPage(page) {
    return await GetFilteredBillResources('api/purchases/tigertickets', {page: page});
}

export async function GetTigerticketByCode(code, page) {
    return await GetFilteredBillResources('api/purchases/tigertickets/search/by-code', {code: code, page: page});
}

export async function GetTigerticketByAccount(accountId, page) {
    return await GetFilteredBillResources('api/purchases/tigertickets/search/by-account-id', {accountId: accountId, page: page});
}

export async function CreateTigerticket(tigerticket) {
    return await CreateBillResource('api/purchases/tigertickets', tigerticket);
}

export async function UpdateTigerticket(tigerticket) {
    return await UpdateBillResource('api/purchases/tigertickets/' + tigerticket.id, tigerticket);
}

export async function GetTigerticket(id) {
    return await GetBillResource('api/purchases/tigertickets/' + id);
}

export async function ResetTigerticket(id) {
    return await GetBillResource('api/purchases/tigertickets/' + id + '/reset');
}

export async function CreateTicketOrder(code, pin, accountId) {
    return await CreateBillResource('api/purchases/order/submit-ticket-order-by-support?accountId=' + accountId, {code: code, pin: pin});
}

//API METHODS - Coupon
export async function GetCouponsOfCouponVariant(variantId, page) {
    return await GetFilteredBillResources('api/purchases/couponVariants/' + variantId + '/coupons', {page: page});
}

export async function GetCouponPage(page) {
    return await GetFilteredBillResources('api/purchases/coupons', {page: page});
}

export async function GetCouponByAccountId(accountId, page) {
    return await GetFilteredBillResources('api/purchases/coupons/search/by-account-id', {accountId: accountId, page: page});
}

export async function GetCouponByCode(code, page) {
    return await GetFilteredBillResources('api/purchases/coupons/search/by-code', {code: code, page: page});
}

export async function GetCouponByVariant(variantId, page) {
    return await GetFilteredBillResources('api/purchases/coupons/search/by-variant-id', {id: variantId, page: page});
}

export async function CreateCoupon(coupon) {
    return await CreateBillResource('api/purchases/coupons', coupon);
}

export async function UpdateCoupon(coupon) {
    return await UpdateBillResource('api/purchases/coupons/' + coupon.id, coupon);
}

export async function GetCoupon(id) {
    return await GetBillResource('api/purchases/coupons/' + id);
}

export async function ResetCoupon(id) {
    return await GetBillResource('api/purchases/coupons/' + id + '/reset');
}
