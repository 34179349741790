import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {FiSettings, GrObjectGroup} from "react-icons/all";
import {InfoModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import AccountMetaData from "./AccountMetaData";
import {DeleteAccount, GetFilteredAccounts} from "./AccountService";
import {PromiseButton, SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Accounts extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            accounts: [],
            filter: props.location.state != null ? props.location.state.filter : {
                page: 0, id: "", email: "", per_page: 20, sortBy: "id", sortDirection: "ASC"
            },
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: AccountMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
            confirmDeletion: false
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadAccountList = this.loadAccountList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Accounts :: Tiger UI";

        await this.loadAccountList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadAccountList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        if(filter.email !== ""){
            //eslint-disable-next-line
            filter.email = filter.email.replaceAll("_", "\_").replaceAll("%", "\%");
        }
        const loadedAccounts = await GetFilteredAccounts(filter);

        if (loadedAccounts.error === true) {
            this.setState(prevState => ({...prevState, error: loadedAccounts}));
        } else {
            loadedAccounts.result.forEach(account => {
                if (this.state.accounts.filter(ex => (ex.isChecked === true)).includes(account)) {
                    account.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            accounts: loadedAccounts.result,
            customColumnData: [],
            lastPage: Math.ceil(loadedAccounts.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadAccountList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Accounts</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View and maintain accounts
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                {
                                    <div className="col-md-2">
                                        <label>Account ID</label>
                                        <input type="text" value={this.state.filter.id}
                                               onChange={(e) => this.updateFilter('id', e.target.value)}/>
                                    </div>
                                }
                                <div className="col-md-4">
                                    <label>E-Mail</label>
                                    <input type="text" value={this.state.filter.email}
                                           onChange={(e) => this.updateFilter('email', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e,this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Accounts List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="accounts"
                                     creationForbidden
                                     sortParams={AccountMetaData.ACCOUNT_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadAccountList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger/>
                            :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.accounts}
                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadAccountList({...this.state.filter, page: event.selected})}
                                onLoadPage={(page) => this.loadAccountList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='accounts'
                                nameInState='accounts'
                                isSpringDataRestResource={true}
                                onToggleResource={(allAccounts) => this.setState(prevState => ({
                                    ...prevState,
                                    accounts: allAccounts
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <InfoModal show={this.state.showDialog.deletionModal}
                           title={"Accounts löschen"}
                           body={this.deletionModalBody()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedAccounts() {
        this.state.accounts
            .filter(account => account.isChecked === true)
            .forEach(account => DeleteAccount(account));

        await this.loadAccountList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
        this.setState(prevState => ({...prevState, confirmDeletion: false}));
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        //NOTE: In case of id = 'accountType' we have to check if the value is already included (since this is a toggle filter)
        if(id === 'accountType') {

            let accountTypes = this.state.filter.accountType;

            //Check if the selected type is already in the filter
            const index = accountTypes.indexOf(value);
            if (index > -1) {
                accountTypes.splice(index, 1);
            } else {
                accountTypes.push(value);
            }
            filter.accountType = accountTypes;
        } else {
            filter[id] = value;
        }

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 0, id: "", email: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter,
        }));

        await this.loadAccountList(filter);
    }

    //--------
    // DIALOGS
    //--------
    deletionModalBody() {
        if (this.state.confirmDeletion) {
            return (
                <div style={{textAlign: "center"}}>
                    <h4>Achtung! Bei Klick auf "Accounts endgültig löschen" werden die ausgewählten Accounts gelöscht und die Daten anonymisiert. Dieser Schritt kann <span style={{color: "red"}}>nicht rückgängig gemacht werden!</span></h4>
                    <br />
                    <div style={{float: "right"}}>
                        <PromiseButton className="form-btn-ci-blue" text="Abbrechen" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showDialog: {
                                ...prevState.showDialog,
                                deletionModal: false
                            },
                            confirmDeletion: false
                        }))}/>
                        <PromiseButton className="form-btn-ci-red" text="Accounts endgültig löschen" onClick={() => this.deleteSelectedAccounts()}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: "center"}}>
                    <h4>Möchtest du die ausgewählten Accounts wirklich löschen?</h4>
                    <br />
                    <div style={{float: "right"}}>
                        <PromiseButton className="form-btn-ci-blue" text="Abbrechen" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            showDialog: {
                                ...prevState.showDialog,
                                deletionModal: false
                            },
                            confirmDeletion: false
                        }))}/>
                        <PromiseButton className="form-btn-ci-red" text="Fortfahren" onClick={() => this.setState(prevState => ({
                            ...prevState,
                            confirmDeletion: true
                        }))}/>
                    </div>
                </div>
            );
        }
    }

    //--------
    // HELPERS
    //--------

    generateProductDetails(products) {

        return (
            <ul style={{listStyle: "square"}}>
                {products.map(product => (
                    <li>{product}</li>
                ))}
            </ul>
        );
    }
}

export default withToast(Accounts);