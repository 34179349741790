import {getToken} from "../auth/SpineTokenStorageService";
import {trackPromise} from "react-promise-tracker";
import GlobalConstants from "../config/GlobalConstants";

export async function GetRestResource(url, fullUrl = false) {

    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    if (!fullUrl) {
        url = GlobalConstants.SEARCH_CLIENT_HOST + url;
    }

    return trackPromise(fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
            .then(function(response) {
                if(!response.ok) {
                    if(response.status === 404) {
                        resourceNotFound = true;
                        return response;
                    } else if (response.status === 502) {
                        gatewayError = true;
                        return response;
                    } else {
                        spineError = true;
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(function (response) {
                if(spineError) {
                    return generateErrorMessage(response);
                } else if(resourceNotFound) {
                    return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
                } else if (gatewayError) {
                    return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(function (error) {
                return {error: true, message: "An unknown error occurred: " + error, result: error}
            }));
}

export async function GetFilteredRestResource(url, filter, accept = null, legacyFilterQuery= false){
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    let length = null;
    accept = 'application/json'

    return trackPromise(fetch(GlobalConstants.SEARCH_CLIENT_HOST + url + (legacyFilterQuery ? createLegacyFilterQuery(filter) : createFilterQuery(filter)), {
        method: 'GET',
        headers: {
            'Accept': accept,
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                length = response.headers.get('X-Total-Count');
                return response.json();
            }
        })
        .then(function (response) {
            if(length == null) {
                if(response["page"] != null) {
                    length = response["page"]["totalElements"];
                } else {
                    //Fallback
                    length = 0;
                }
            }
            let convertedResponse = ConvertHateosResponse(response);
            if(spineError) {
                return generateErrorMessage(convertedResponse);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...convertedResponse, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: convertedResponse, length: length, pagination: response.page}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error, length: 0}
        }));
}

export async function UpdateRestResourceUriList(url, resource, fullUrl = false) {
    let token = getToken();

    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    if (!fullUrl) {
        url = GlobalConstants.SEARCH_CLIENT_HOST + url;
    }

    return trackPromise(fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'text/uri-list',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: resource,
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                return response.json();
            }
        })
        .then(function(response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(async function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function UpdateRestResource(url, resource, fullUrl = false) {
    //Security check
    let token = getToken();

    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    if (!fullUrl) {
        url = GlobalConstants.SEARCH_CLIENT_HOST + url;
    }

    return trackPromise(fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
            .then(function(response) {
                if(!response.ok) {
                    if (response.status === 404) {
                        resourceNotFound = true;
                        return response;
                    } else if (response.status === 502) {
                        gatewayError = true;
                        return response;
                    } else {
                        spineError = true;
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(function(response) {
                if(spineError) {
                    return generateErrorMessage(response);
                } else if(resourceNotFound) {
                    return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
                } else if (gatewayError) {
                    return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(async function(error) {
                return {error: true, message: "An unknown error occurred: " + error, result: error}
            }));
}

export async function DeleteRestResource(url, dtoIsReturned = true) {
    //Security check
    let token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SEARCH_CLIENT_HOST + url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
            .then(function(response) {
                if(!response.ok) {
                    if (response.status === 404) {
                        resourceNotFound = true;
                        return response;
                    } else if (response.status === 502) {
                        gatewayError = true;
                        return response;
                    } else {
                        spineError = true;
                        return response.json();
                    }
                } else {
                    //Result ok
                    if(dtoIsReturned) {
                        //In case of an ResponseEntity with a dto
                        return response.json();
                    } else {
                        //Void
                        return response;
                    }
                }
            })
            .then(function (response) {
                if(spineError) {
                    return generateErrorMessage(response);
                } else if(resourceNotFound) {
                    return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
                } else if (gatewayError) {
                    return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(function(error) {
                return {error: true, message: "An unknown error occurred: " + error, result: error}
            }))
}

//HELPERS

export function ConvertHateosResponse(response) {
    if(response["_embedded"] != null) {
        //Hateos resource -> Get the result list
        //Get the name of the resource list
        let keys = Object.keys(response["_embedded"]);

        if (keys.length > 0) {
            return response["_embedded"][keys[0]];
        } else {
            return [];
        }
    } else if (response["_links"] != null) {
        return [];
    } else if (Array.isArray(response)){
        //No Hateos resource -> return the response
        return response;
    } else {
        //Empty hateos resource
        return response;
    }
}

function generateErrorMessage(response) {
    let message = "";

    if (response.errorCode != null) {
        message = message + "Code: " + response.errorCode + "\n";
    }

    if(response.info != null) {
        message = message + "Info: " + response.info + "\n";
    }

    if(response.message != null) {
        message = message + "Message: " + response.message + "\n";
    }

    return {error: true, message: message, result: response};
}

export function createFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "" && key !== 'sortDirection') { //sortDirection value will be added to the sort param value with comma separated,
                                                                                           // and to make the confusion complete the name of this param is also "sortDirection"
            //Check for the data type of the filter param
            let paramName = key;
            if (key === 'per_page') {
                paramName = 'pageSize';
            } else if (key === 'sortBy') {
                paramName = 'sortDirection';
            }
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + encodeURIComponent(entry)));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + paramName + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + paramName + "=" + (key === "sortBy" ? filter[key] + "," + filter['sortDirection'] : encodeURIComponent(filter[key])) + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    return query;
}

export function createLegacyFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "") {
            //Check for the data type of the filter param
            let paramName = key;
            if (key === 'per_page') {
                paramName = 'pageSize';
            }
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + encodeURIComponent(entry)));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + paramName + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + paramName + "=" + (key === "sortBy" ? filter[key] : encodeURIComponent(filter[key])) + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    return query;
}

export async function CreateRestResource(url, resource, dtoIsReturned = true){

    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SEARCH_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
            .then(function (response){
                if(!response.ok) {
                    if (response.status === 404) {
                        resourceNotFound = true;
                        return response;
                    } else if (response.status === 502) {
                        gatewayError = true;
                        return response;
                    } else {
                        spineError = true;
                        return response.json();
                    }
                } else {
                    //Result ok
                    if(dtoIsReturned) {
                        //In case of an ResponseEntity with a dto
                        return response.json();
                    } else {
                        //Void
                        return response;
                    }
                }
            })
            .then(function (response){
                if(spineError) {
                    return generateErrorMessage(response);
                } else if(resourceNotFound) {
                    return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
                } else if (gatewayError) {
                    return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(function (error){
                return {error: true, message: "An unknown error occurred: " + error, result: error}
            }));
}
