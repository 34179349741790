import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection} from "../../generators/DetailsGenerator";
import {
    CancelDigiticket,
    CreateDigiticket,
    CreateTicketOrder,
    GetDigiticket,
    ResetDigiticket,
    UpdateDigiticket
} from "./TicketService";
import TicketMetaData from "./TicketMetaData";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {OrderTicketDialog} from "./OrderTicketDialog";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class DigiticketDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            digiticket: {},
            originalDigiticket: {},
            sections: {
                general: true,
                ticketDetails: true,
                statusDetails: true,
            },
            showResetModal: false,
            showOrderDialog: false,
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "digiticket " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New digiticket :: Tiger UI";
        }
        await this.loadDigiticket();
    }

    //--------
    //Loading
    //--------

    async loadDigiticket() {
        let loadedDigiticket = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedDigiticket = await GetDigiticket(this.state.id);

            if (!loadedDigiticket.error) {
                const digiticket = loadedDigiticket.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    digiticket: digiticket,
                    originalDigiticket: digiticket,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedDigiticket}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                digiticket: {
                    id: null,
                    source: 'SUPPORT',
                    createdBy: 'system'
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.digiticket.id != null ? "Details of digiticket " + this.state.digiticket.id : "Create a digiticket"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/tickets",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.digiticket.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        digiticket: this.state.originalDigiticket
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateDigiticket()} />
                    }
                    {this.state.digiticket.used === true ? (
                        <button style={{marginLeft: "20px"}} className="form-btn-ci-light-blue"
                                onClick={() => this.setState(prevState => ({...prevState, showResetModal: true}))}>Reset
                            digiticket
                        </button>
                    ) : (
                        <button style={{marginLeft: "20px"}} className="form-btn-ci-red"
                                onClick={() => this.setState(prevState => ({...prevState, showCancelModal: true}))}>Cancel
                            digiticket
                        </button>
                    )}
                    <button className={this.state.digiticket.used ? "form-btn-ci-off" : "form-btn-ci-green"}
                            onClick={() => this.setState(prevState => ({...prevState, showOrderDialog: true}))}
                        disabled={this.state.digiticket.used}>
                        Create Order
                    </button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="digiticket"
                        fields={TicketMetaData.DIGITICKET_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDigiticket()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="digiticket"
                        fields={TicketMetaData.DIGITICKET_DETAILS_TICKET}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDigiticket()}
                        sectionId="ticketDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Ticket</span>}
                    />

                    <DetailsSection
                        nameInState="digiticket"
                        fields={TicketMetaData.DIGITICKET_DETAILS_STATUS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateDigiticket()}
                        sectionId="statusDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Status</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/tickets",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateDigiticket()} />
                    }
                </div>

                <ActionModal show={this.state.showResetModal}
                             actionButtonText={"Continue"}
                             size='sm'
                             onAction={() => this.resetDigiticket()}
                             onHide={() => this.setState(prevState => ({...prevState, showResetModal: false}))}
                             title="Reset digiticket "
                             body={
                                 <div style={{textAlign: "center"}}>
                                     By resetting the digiticket, redemption status will be deleted so it can be
                                     redeemed again. Continue?
                                 </div>
                             }/>

                <ActionModal show={this.state.showCancelModal}
                             actionButtonText={"Continue"}
                             size='sm'
                             onAction={() => this.cancelDigiticket()}
                             onHide={() => this.setState(prevState => ({...prevState, showCancelModal: false}))}
                             title="Cancel digiticket "
                             body={
                                 <div style={{textAlign: "center"}}>
                                     Cancel the digiticket?
                                 </div>
                             }/>

                <InfoModal show={this.state.showOrderDialog}
                             onHide={() => this.setState(prevState => ({...prevState, showOrderDialog: false}))}
                             title={"Create order for ticket " + this.state.digiticket.code}
                             body={
                                 <OrderTicketDialog code={this.state.digiticket.code} pin={this.state.digiticket.pin}
                                                    onOrder={(accountId) => this.createTicketOrder(accountId)}/>
                             }/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async createTicketOrder(accountId) {
        let response = await CreateTicketOrder(this.state.digiticket.code, this.state.digiticket.pin, accountId);
        if (!response.error) {
            this.loadDigiticket().then(r => this.setState(prevState => ({...prevState, showOrderDialog: false})));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async saveOrUpdateDigiticket() {
        let digiticket = {};
        if(this.state.digiticket.id != null) {
            digiticket = await UpdateDigiticket(this.state.digiticket);
        } else {
            digiticket = await CreateDigiticket(this.state.digiticket);
        }

        if (!digiticket.error) {
            this.setState((prevState) =>({...prevState, digiticket: digiticket.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The digiticket has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: digiticket}));
        }
    }

    async resetDigiticket() {
        let response = await ResetDigiticket(this.state.id);
        if(!response.error) {
            this.loadDigiticket().then(r => {
                this.props.addToast("digiticket has been reset successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showResetModal: false}));
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async cancelDigiticket() {
        let response = await CancelDigiticket(this.state.id);
        if(!response.error) {
            this.loadDigiticket().then(r => {
                this.props.addToast("digiticket has been canceled successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showCancelModal: false}));
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
}


export default withToast(DigiticketDetails);